import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import ListItemText from "@material-ui/core/ListItemText";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import React, {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {
    completedTaskIds,
    currentGameData,
    currentGameInstance,
    currentScore,
    currentUserData,
    discoveredTaskIds,
    discoveredTasks,
    Task
} from "../redux";
import {useSelector} from "react-redux";
import {formatTime, mysqlToDate} from "../functions";
import {green, red} from "@material-ui/core/colors";
import styles from './TaskList.module.scss';
import {
    KEY_ASK_SURE_LEAVE,
    KEY_ASK_SURE_LEAVE_TEXT,
    KEY_CANCEL,
    KEY_LEAVE,
    KEY_NO_PUZZLES,
    KEY_SWITCH_TO_SCANNER,
    Translated
} from "../translations";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Timer from "@material-ui/icons/Timer";
import {Star} from "@material-ui/icons";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton} from "@material-ui/core";
import {useAuth} from "../firebase";

export type TaskProps = {
    task: Task
};

export function TaskListItem({task}: TaskProps) {
    const history = useHistory();
    const userData = useSelector(currentUserData)[task.id];

    let icon;
    let clazz = styles.listItem;
    if (userData.correct_answer_at !== null) {
        icon = <AssignmentTurnedInIcon style={{color: green['400']}}/>;
    } else if (userData.tries === task.question.max_tries) {
        icon = <AssignmentLateIcon style={{color: red['400']}}/>;
        clazz += ' ' + styles.incorrectAnswer;
    } else {
        icon = <AssignmentIcon/>;
    }


    return <ListItem className={clazz} onClick={() => history.push(`/task/${task.id}`)} button>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={task.instructions.title}/>
    </ListItem>
}

export function TimeFromStart() {
    const gameInstance = useSelector(currentGameInstance);

    const computeTimeDiff = useCallback(() => {
        const now = new Date();
        const start = gameInstance ? mysqlToDate(gameInstance.started_at) : now;
        return formatTime((+now - +start) / 1000);
    }, [gameInstance]);

    const [time, setTime] = useState(computeTimeDiff());

    useEffect(() => {
        const id = setInterval(() => setTime(computeTimeDiff()), 1000);
        return () => clearInterval(id);
    }, [computeTimeDiff, gameInstance]);

    return <Typography variant={"body2"} component={"span"}>{time}</Typography>;
}

export function TopBar() {
    const gameData = useSelector(currentGameData);
    const completed = useSelector(completedTaskIds);
    const discovered = useSelector(discoveredTaskIds);
    const score = useSelector(currentScore);
    const auth = useAuth();
    const [open, setOpen] = useState(false);

    const askLeaveGame = () => {
        setOpen(true);
    };

    const leaveGame = () => {
        auth.signOut();
        localStorage.clear();
        window.location.href = '/';
    };

    if (!gameData) {
        return <>Not in a game!</>;
    }

    return <>
        <Container className={styles.topBar}>
            <Grid alignItems={"center"} justify={"space-between"} container>
                <Grid style={{flexGrow: 1}} item>
                    <Grid direction={"column"} container>
                        <Grid className={styles.verticalMiddle} style={{paddingBottom: '4px'}} item>
                            <Grid container>
                                <Grid style={{minWidth: '80px'}} item>
                                    <AssignmentTurnedInIcon/> <Typography variant={"body2"}
                                                                          component={"span"}>{completed.length}</Typography>
                                </Grid>
                                <Grid item>
                                    <Timer/> <TimeFromStart/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid className={styles.verticalMiddle} item>
                            <Grid container>
                                <Grid style={{minWidth: '80px'}} item>
                                    <AssignmentIcon/> <Typography component={"span"}
                                                                  variant={"body2"}>{discovered.length} / {gameData.tasks.length.toString()}</Typography>
                                </Grid>
                                <Grid item>
                                    <Star/> <Typography variant={"body2"} component={"span"}>{score}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <IconButton component="span" onClick={askLeaveGame}>
                        <ExitToApp/>
                    </IconButton>
                </Grid>
            </Grid>
        </Container>

        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle><Translated translationKey={KEY_ASK_SURE_LEAVE}/></DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Translated translationKey={KEY_ASK_SURE_LEAVE_TEXT}/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                    <Translated translationKey={KEY_CANCEL}/>
                </Button>
                <Button onClick={() => leaveGame()} color="primary" autoFocus>
                    <Translated translationKey={KEY_LEAVE}/>
                </Button>
            </DialogActions>
        </Dialog>

    </>;
}

export type TasksProps = {
    tasks: Task[],
}

export function TaskList({tasks}: TasksProps) {
    if (tasks.length === 0) {
        return <Container className={"no-puzzles"}>
            <img src={'./map.svg'} alt={"Look for QR codes"}/>
            <Typography variant={"h5"}><Translated translationKey={KEY_NO_PUZZLES}/></Typography>
            <Typography><Translated translationKey={KEY_SWITCH_TO_SCANNER}/></Typography>
        </Container>;
    }

    return <>
        <TopBar/>
        <Divider/>
        <List>{tasks.map(it => <TaskListItem key={it.id} task={it}/>)}</List>
    </>;
}

export function Tasks() {
    const tasks = useSelector(discoveredTasks);

    return <TaskList tasks={tasks}/>
}
