import {useHistory, useLocation} from "react-router-dom";
import {QrReader} from "@blackbox-vision/react-qr-reader";
import React, {useState} from "react";
import Alert from "@material-ui/lab/Alert";
import {useDispatch, useSelector, useStore} from "react-redux";
import {currentGameData, currentGameInstance, discoveredTaskIds, discoverTask} from "../redux";
import {
    KEY_CAMERA_BLOCKED, KEY_CAMERA_ON_HTTP,
    KEY_INVALID_QR_CODE,
    KEY_LOADING_CAMERA,
    KEY_SCANNING,
    KEY_TASK_FOUND_ALREADY,
    KEY_TASK_NOT_FROM_THIS_GAME,
    useTranslated
} from "../translations";
import {useRealtimeDatabase} from "../firebase";

export function Qr() {
    const history = useHistory();
    const store = useStore();
    const dispatch = useDispatch();
    const db = useRealtimeDatabase();
    const gameInstance = useSelector(currentGameInstance);
    const stringScanning = useTranslated(KEY_SCANNING);
    const stringTaskNotFromGame = useTranslated(KEY_TASK_NOT_FROM_THIS_GAME);
    const stringTaskAlreadyFound = useTranslated(KEY_TASK_FOUND_ALREADY);
    const stringInvalidQrCode = useTranslated(KEY_INVALID_QR_CODE);
    const stringCameraBlocked = useTranslated(KEY_CAMERA_BLOCKED);
    const stringCameraOnHttp = useTranslated(KEY_CAMERA_ON_HTTP);
    const [status, setStatus] = useState(useTranslated(KEY_LOADING_CAMERA));
    const [severity, setSeverity] = useState('info' as ("info" | "error"));

    const scanDone = (data?: string) => {
        setSeverity('info');
        if (!data) {
            return setStatus(stringScanning);
        }

        try {
            const url = new URL(data);
            const gameId = url.pathname.split(':')[0];
            const taskId = url.pathname.split(':')[1];
            setStatus(`You scanned code for task ${taskId} in ${gameId}.`);

            const gameData = currentGameData(store.getState());
            const discoveredIds = discoveredTaskIds(store.getState());

            // check if this is correct game and task
            if (gameData && gameData.id !== gameId) {
                return setStatus(stringTaskNotFromGame);
            }

            if (gameData && !gameData.tasks.some(it => it.id === taskId)) {
                return setStatus(stringTaskNotFromGame);
            }

            if (discoveredIds.includes(taskId)) {
                return setStatus(stringTaskAlreadyFound);
            }

            if (gameInstance != null) {
                dispatch(discoverTask(db, gameInstance, taskId));
            }

            navigator.vibrate(150);
            history.push(`/task/${taskId}`)
        } catch (e) {
            setStatus(stringInvalidQrCode);
        }
    };

    const onError = (e: any) => {
        setSeverity('error');
        if (e.toString().includes('NotAllowedError')) {
            setStatus(stringCameraBlocked);
        } else if (window.location.protocol === 'http:') {
            setStatus(stringCameraOnHttp);
        } else {
            setStatus(`Error: ${e}`);
        }
    };

    return <div>
        <QrReader
            onScan={scanDone}
            onLoad={() => setStatus(stringScanning)}
            onError={onError}
            facingMode={"environment"}
            resolution={256}
            showViewFinder={true}
            style={{width: '100%'}}
        />
        <Alert severity={severity} style={{margin: '1em'}}>{status}</Alert>
    </div>
}
