import React from 'react';
import {Container, Typography} from '@material-ui/core';
import styles from './Finish.module.scss'
import {KEY_FINISH_TEXT, KEY_FINISH_TITLE, Translated} from "../translations";

export function Finish() {
    return <Container className={styles.gameDetails}>
        <Typography variant={"h4"}><Translated translationKey={KEY_FINISH_TITLE}/></Typography>
        <Typography><Translated translationKey={KEY_FINISH_TEXT}/></Typography>
    </Container>;
}
