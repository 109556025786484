import {AxiosInstance} from "axios";
import {GameInfo} from "./redux";

const axios = require('axios').default;

const API_BASE_URL = 'https://museumhunt.brainteaselava.sk/';

export class ApiInner {
    private axios: AxiosInstance;

    constructor() {
        this.axios = axios.create({baseURL: API_BASE_URL});
    }

    async getGameInfo(joinCode: string): Promise<GameInfo> {
        return (await this.axios.get(`/join.php?code=${joinCode}`)).data;
    }
}

export const Api = new ApiInner();
