/// data types ///
import {createSelector} from "reselect";
import {findCorrectAnswer, perQuestionPts} from "./functions";
import {ThunkAction} from "redux-thunk";
import firebase from "firebase/app";
import "firebase/database";

export type TaskId = string;

export interface Instructions {
    title: string;
    paragraphs: string[];
    image?: string;
    video?: string;
    audio?: string;
}

export interface Hint {
    text: string;
    penalty_pts: number;
}

export interface Answer {
    id: string;
    title: string;
    base_points: number;
    is_correct: boolean;
}

export interface GameInstance {
    id: string;
    started_at: string;
    finished_at: string;
    created_at: string;
    type: string;
    code: string;
    password: string;
}

export interface GameInfo extends GameInstance {
    jwt: string;
    game: Game;
}


export const QUESTION_TYPE_OPTIONS = 'options';
export const QUESTION_TYPE_TEXT = 'text';

export type QuestionType = typeof QUESTION_TYPE_OPTIONS | typeof QUESTION_TYPE_TEXT;

export const EVALUATION_CONSTANT_PENALTY = 'constant_penalty';
export const EVALUATION_1_DIVIDED_TRIES = '1_divided_tries';
export const EVALUATION_HALVE_PTS_ON_INCORRECT = 'halve_pts_on_incorrect';
export const EVALUATION_UNLIMITED_TRIES = 'unlimited_tries';

export type EvaluationAlgorithm =
    typeof EVALUATION_HALVE_PTS_ON_INCORRECT
    | typeof EVALUATION_UNLIMITED_TRIES
    | typeof EVALUATION_1_DIVIDED_TRIES
    | typeof EVALUATION_CONSTANT_PENALTY;

export interface Question {
    text: string;
    type: QuestionType;
    hint: Hint | null;
    answers: Answer[];
    max_tries: number;
    evaluation_algorithm: EvaluationAlgorithm;
    wrong_answer_penalty: number | null;
}

export interface Task {
    id: TaskId;
    instructions: Instructions;
    question: Question;
}

export interface DiscoveredTaskUserData {
    last_answer: string | null;
    tries: number;
    found_at: Date;
    used_hint_at: Date | null;
    first_answer_at: Date | null;
    correct_answer_at: Date | null;
}

export interface Game {
    id: string;
    map: string;
    translations: { [key: string]: string };
    tasks: Task[];
}

/// actions ///
export const TASK_DISCOVERED_ACTION = 'discovered';
export const TASK_SUBMIT_ANSWER_ACTION = 'answer';
export const JOIN_GAME_ACTION = 'join';
export const TASK_USE_HINT_ACTION = 'hint';
export const MERGE_USER_DATA_ACTION = 'merge';

export interface TaskDiscoveredAction {
    type: typeof TASK_DISCOVERED_ACTION;
    taskId: TaskId;
}

export interface TaskSubmitAnswerAction {
    type: typeof TASK_SUBMIT_ANSWER_ACTION;
    taskId: TaskId;
    answer: string;
}

export interface TaskDiscoveredAction {
    type: typeof TASK_DISCOVERED_ACTION;
    taskId: TaskId;
}

export interface TaskUseHintAction {
    type: typeof TASK_USE_HINT_ACTION;
    taskId: TaskId;
}

export interface JoinGameAction {
    type: typeof JOIN_GAME_ACTION;
    game: Game;
    gameInstance: GameInstance;
}

export interface MergeUserDataAction {
    type: typeof MERGE_USER_DATA_ACTION;
    userData: { [key: string]: DiscoveredTaskUserData };
}

export type Action =
    TaskDiscoveredAction
    | TaskSubmitAnswerAction
    | TaskUseHintAction
    | JoinGameAction
    | MergeUserDataAction;

/// thunk action creators ///

export function discoverTask(db: firebase.database.Database, gameInstance: GameInstance, taskId: TaskId): ThunkAction<void, ApplicationState, {}, Action> {
    return async (dispatch) => {
        dispatch(taskDiscovered(taskId));

        await db.ref(`games/${gameInstance.code}/user_data/${taskId}`)
            .transaction(taskUserData => ({
                    ...taskUserData,
                    discovered: true,
                    found_at: firebase.database.ServerValue.TIMESTAMP,
                })
            );
    };
}

export function submitAnswer(db: firebase.database.Database, gameInstance: GameInstance, taskId: TaskId, answer: string): ThunkAction<void, ApplicationState, {}, Action> {
    return async (dispatch, getState) => {
        answer = answer.substr(0, 128);

        dispatch(taskSubmitAnswer(taskId, answer));
        let gameData = currentGameData(getState());
        if (!gameData) {
            console.error(`Submitted answer while not in game!`);
            return;
        }

        let task = gameData.tasks.find(it => it.id === taskId);

        if (!task) {
            console.error(`Submitted answer for invalid taskId ${taskId}!`);
            return;
        }

        let ans = findCorrectAnswer(task.question, answer);
        let isCorrect = !!ans;

        await db.ref(`games/${gameInstance.code}/user_data/${taskId}`)
            .transaction(taskUserData => {
                if (taskUserData.correct_answer_at) {
                    return taskUserData;
                }

                return {
                    ...taskUserData,
                    tries: (taskUserData.tries ?? 0) + 1,
                    last_answer: answer,
                    first_answer_at: taskUserData.first_answer_at ?? firebase.database.ServerValue.TIMESTAMP,
                    correct_answer_at: isCorrect ? firebase.database.ServerValue.TIMESTAMP : null,
                };
            });
    };
}


export function taskUseHint(db: firebase.database.Database, gameInstance: GameInstance, taskId: TaskId): ThunkAction<void, ApplicationState, {}, Action> {
    return async (dispatch) => {
        dispatch(taskUseHintInternal(taskId));

        await db.ref(`games/${gameInstance.code}/user_data/${taskId}`)
            .transaction(taskUserData => {
                    if (taskUserData.used_hint_at) {
                        return taskUserData;
                    }

                    return {
                        ...taskUserData,
                        used_hint_at: firebase.database.ServerValue.TIMESTAMP,
                    };
                }
            );
    };
}


/// action creators ///
export function taskDiscovered(taskId: TaskId): TaskDiscoveredAction {
    return {type: TASK_DISCOVERED_ACTION, taskId};
}

export function taskSubmitAnswer(taskId: TaskId, answer: string): TaskSubmitAnswerAction {
    return {type: TASK_SUBMIT_ANSWER_ACTION, taskId, answer};
}

export function taskUseHintInternal(taskId: TaskId): TaskUseHintAction {
    return {type: TASK_USE_HINT_ACTION, taskId};
}

export function joinGame(game: Game, gameInstance: GameInstance): JoinGameAction {
    return {type: JOIN_GAME_ACTION, game, gameInstance};
}

export function mergeUserData(userData: { [key: string]: DiscoveredTaskUserData }): MergeUserDataAction {
    return {type: MERGE_USER_DATA_ACTION, userData};
}

/// state ///
export interface ApplicationState {
    game: Game | null;
    gameInstance: GameInstance | null;
    userData: { [key: string]: DiscoveredTaskUserData };
}

export const INITIAL_STATE: ApplicationState = {
    game: null,
    gameInstance: null,
    userData: {},
};

/// reducer ///
export function museumAppLogic(state: ApplicationState = INITIAL_STATE, action: Action): ApplicationState {
    switch (action.type) {
        case TASK_DISCOVERED_ACTION:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    [action.taskId]: {
                        last_answer: null,
                        tries: 0,
                        correct_answer_at: null,
                        first_answer_at: null,
                        found_at: new Date(),
                        used_hint_at: null,
                    }
                }
            };
        case TASK_SUBMIT_ANSWER_ACTION:
            let userDatum = state.userData[action.taskId];
            let isCorrect = correctAnswersSelectorFactory(action.taskId)(state).some(ans => ans.id === action.answer);
            return {
                ...state,
                userData: {
                    ...state.userData,
                    [action.taskId]: {
                        ...userDatum,
                        tries: userDatum.tries + 1,
                        last_answer: action.answer,
                        first_answer_at: userDatum.first_answer_at ?? new Date(),
                        correct_answer_at: isCorrect ? new Date() : null,
                    }
                }
            };
        case JOIN_GAME_ACTION:
            return {...state, game: action.game, gameInstance: action.gameInstance};
        case TASK_USE_HINT_ACTION:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    [action.taskId]: {
                        ...state.userData[action.taskId],
                        used_hint_at: new Date(),
                    }
                }
            };
        case MERGE_USER_DATA_ACTION:
            const userData: { [key: string]: DiscoveredTaskUserData } = {};
            for (const [key, value] of Object.entries(action.userData)) {
                userData[key] = {
                    last_answer: value.last_answer ?? null,
                    tries: value.tries ?? 0,
                    correct_answer_at: value.correct_answer_at ?? null,
                    first_answer_at: value.first_answer_at ?? null,
                    found_at: value.found_at ?? null,
                    used_hint_at: value.used_hint_at ?? null,
                }
            }

            return {
                ...state,
                userData: userData,
            };
        default:
            return state;
    }
}

/// selectors ///
export const currentGameInstance = (state: ApplicationState) => state.gameInstance;
export const currentGameData = (state: ApplicationState) => state.game;
export const currentUserData = (state: ApplicationState) => state.userData;
export const isInGame = (state: ApplicationState) => state.gameInstance !== null;

export const discoveredTaskIds = createSelector([currentUserData], (userData) => Object.keys(userData));
export const completedTaskIds = createSelector([currentUserData], (data) => Object.keys(data).filter(it => data[it].correct_answer_at != null));
export const taskIdsWithHint = createSelector([currentUserData], (data) => Object.keys(data).filter(it => data[it].used_hint_at != null));
export const discoveredTasks = createSelector(
    [currentGameData, discoveredTaskIds],
    (gameData, discovered) => {
        if (gameData == null) {
            return [];
        }

        return gameData.tasks.filter(it => discovered.includes(it.id));
    }
);

export const correctAnswersSelectorFactory = (taskId: string) => createSelector(
    [currentGameData],
    (gameData) => gameData?.tasks.find(it => it.id === taskId)?.question.answers.filter(ans => ans.is_correct) ?? []
);

export const currentScore = createSelector(
    [currentGameData, currentUserData],
    (gameData, userData) => {
        if (gameData == null) return 0;


        let score = Object.keys(userData)
            .map(k => [gameData.tasks.find(it => it.id === k) ?? null, userData[k]])
            .map(t => perQuestionPts(t as [Task | null, DiscoveredTaskUserData]))
            .reduce((a, e) => a + e, 0);

        return isNaN(score) ? 0 : score;
    }
);
