import {
    Answer,
    DiscoveredTaskUserData,
    EVALUATION_1_DIVIDED_TRIES,
    EVALUATION_CONSTANT_PENALTY,
    EVALUATION_HALVE_PTS_ON_INCORRECT,
    EVALUATION_UNLIMITED_TRIES,
    Question,
    QUESTION_TYPE_TEXT,
    Task
} from "./redux";

var unorm = require('unorm');

export function formatTime(seconds: number) {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.round(seconds % 60);
    return `${h.toString(10).padStart(2, '0')}:${m.toString(10).padStart(2, '0')}:${s.toString(10).padStart(2, '0')}`;
}

export function mysqlToDate(mysql: string): Date {
    return new Date(mysql.replace(' ', 'T'));
}

export const computeTriesFactor = (task: Task, data: DiscoveredTaskUserData, basePoints: number) => {
    switch (task.question.evaluation_algorithm) {
        case EVALUATION_UNLIMITED_TRIES:
            return 1;
        case EVALUATION_HALVE_PTS_ON_INCORRECT:
            return Math.pow(0.5, Math.max(0, data.tries - 1));
        case EVALUATION_1_DIVIDED_TRIES:
            return 1 / data.tries;
        case EVALUATION_CONSTANT_PENALTY:
            return (basePoints - (task.question.wrong_answer_penalty ?? 1) * data.tries) / basePoints;
    }
};

/**
 * Compares two strings while ignoring white-space, punctuation
 * accents and case of the strings.
 */
export function lossyStringCompare(a: string, b: string): boolean {
    const punctuationRegEx = /[!-/:-@[-`{-~¡-©«-¬®-±´¶-¸»¿×÷˂-˅˒-˟˥-˫˭˯-˿͵;΄-΅·϶҂՚-՟։-֊־׀׃׆׳-״؆-؏؛؞-؟٪-٭۔۩۽-۾܀-܍߶-߹।-॥॰৲-৳৺૱୰௳-௺౿ೱ-ೲ൹෴฿๏๚-๛༁-༗༚-༟༴༶༸༺-༽྅྾-࿅࿇-࿌࿎-࿔၊-၏႞-႟჻፠-፨᎐-᎙᙭-᙮᚛-᚜᛫-᛭᜵-᜶។-៖៘-៛᠀-᠊᥀᥄-᥅᧞-᧿᨞-᨟᭚-᭪᭴-᭼᰻-᰿᱾-᱿᾽᾿-῁῍-῏῝-῟῭-`´-῾\u2000-\u206e⁺-⁾₊-₎₠-₵℀-℁℃-℆℈-℉℔№-℘℞-℣℥℧℩℮℺-℻⅀-⅄⅊-⅍⅏←-⏧␀-␦⑀-⑊⒜-ⓩ─-⚝⚠-⚼⛀-⛃✁-✄✆-✉✌-✧✩-❋❍❏-❒❖❘-❞❡-❵➔➘-➯➱-➾⟀-⟊⟌⟐-⭌⭐-⭔⳥-⳪⳹-⳼⳾-⳿⸀-\u2e7e⺀-⺙⺛-⻳⼀-⿕⿰-⿻\u3000-〿゛-゜゠・㆐-㆑㆖-㆟㇀-㇣㈀-㈞㈪-㉃㉐㉠-㉿㊊-㊰㋀-㋾㌀-㏿䷀-䷿꒐-꓆꘍-꘏꙳꙾꜀-꜖꜠-꜡꞉-꞊꠨-꠫꡴-꡷꣎-꣏꤮-꤯꥟꩜-꩟﬩﴾-﴿﷼-﷽︐-︙︰-﹒﹔-﹦﹨-﹫！-／：-＠［-｀｛-･￠-￦￨-￮￼-�]|\ud800[\udd00-\udd02\udd37-\udd3f\udd79-\udd89\udd90-\udd9b\uddd0-\uddfc\udf9f\udfd0]|\ud802[\udd1f\udd3f\ude50-\ude58]|\ud809[\udc00-\udc7e]|\ud834[\udc00-\udcf5\udd00-\udd26\udd29-\udd64\udd6a-\udd6c\udd83-\udd84\udd8c-\udda9\uddae-\udddd\ude00-\ude41\ude45\udf00-\udf56]|\ud835[\udec1\udedb\udefb\udf15\udf35\udf4f\udf6f\udf89\udfa9\udfc3]|\ud83c[\udc00-\udc2b\udc30-\udc93]/g;
    let canonize = (t: string) => unorm.nfd(t
        .toLowerCase()
        .replace(/\s/g, '')
        .replace(punctuationRegEx, ''))
        .replace(/[\u0300-\u036f]/g, "");

    return canonize(a) === canonize(b);
}

/**
 * Returns Answer is question is answered correctly, undefined otherwise
 */
export function findCorrectAnswer(question: Question, userAnswer: string | null): Answer | undefined {
    if (userAnswer === null) return;

    if (question.type === QUESTION_TYPE_TEXT) {
        return question.answers.find(ans => ans.is_correct && lossyStringCompare(userAnswer ?? "", ans.title));
    } else {
        return question.answers.find(ans => ans.is_correct && ans.id === userAnswer);
    }
}

export const perQuestionPts = (t: [Task | null, DiscoveredTaskUserData]) => {
    const [task, data] = t;

    if (task == null) return 0;

    const basePtsFromAnswer = findCorrectAnswer(task.question, data.last_answer)?.base_points ?? 0;
    const triesFactor = computeTriesFactor(task, data, basePtsFromAnswer);
    const hintPenalty = data.used_hint_at === null ? 0 : task.question.hint?.penalty_pts ?? 0;

    const pts = Math.max(0, basePtsFromAnswer * triesFactor - hintPenalty);

    return isNaN(pts) ? 0 : pts;
};

// https://stackoverflow.com/a/8260383/721809
export function youtube_parser(url: string) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length == 11) ? match[7] : false;
}
