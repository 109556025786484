import React, {useState} from "react";
import {useSelector} from "react-redux";
import {currentGameData} from "../redux";
import {Box, CircularProgress} from "@material-ui/core";
import styles from './Map.module.scss'
import {KEY_MAP, useTranslated} from "../translations";

export function Map() {
    const gameData = useSelector(currentGameData);
    const [isLoading, setLoading] = useState(true);

    return <Box className={styles.map}>
        {isLoading && <CircularProgress className={styles.loader}/>}
        <Box className={styles.mapImage + (isLoading ? '' : ' ' + styles.loaded)}>
            <img alt={useTranslated(KEY_MAP)} src={gameData?.map ?? ''}
                 onLoad={() => setLoading(false)}/>
        </Box>
    </Box>;
}
