import React from "react";
import {useSelector} from "react-redux";
import {currentGameData} from "./redux";

export function useTranslated(key: string, replacement?: string): string {
    const gameData = useSelector(currentGameData);
    return gameData?.translations?.[key]?.replace('#', replacement ?? '#') ?? key;
}

export type TranslatedProps = {
    translationKey: string;
    replacement?: string;
};

export function Translated({translationKey, replacement}: TranslatedProps) {
    return <>{useTranslated(translationKey, replacement)}</>;
}

export const KEY_TASK_NOT_FOUND = 'app_task_not_found'; // Sorry, task not found.
export const KEY_TASK_NOT_DISCOVERED = 'app_task_not_discovered'; // You need to first find QR code for this task.
export const KEY_QUESTION = 'app_question'; // Question
export const KEY_CHECK_ANSWER = 'app_check_answer'; // Check answer
export const KEY_GIVE_HINT = 'app_give_hint'; // Hint
export const KEY_LOADING_CAMERA = 'app_loading_camera'; // Loading your camera...
export const KEY_SCANNING = 'app_scanning'; // Scanning...
export const KEY_TASK_FOUND_ALREADY = 'app_task_found_already'; // You have already unlocked this task.
export const KEY_TASK_NOT_FROM_THIS_GAME = 'app_task_not_from_this_game'; // This task is not from this game.
export const KEY_INVALID_QR_CODE = 'app_invalid_qr_code'; // This QR code is not from this game!
export const KEY_CAMERA_BLOCKED = 'app_camera_blocked'; // You blocked camera access to this application.
export const KEY_CAMERA_ON_HTTP = 'app_camera_on_http'; // Camera is not available on insecure HTTP origins.
export const KEY_MAP = 'app_map'; // This QR code is not from this game!
export const KEY_FINISH_TITLE = 'app_finish_title'; // Game finished
export const KEY_FINISH_TEXT = 'app_finish_text'; // Thank you for playing! We will send you detailed results of your game later.
export const KEY_YOUR_ANSWER = 'app_your_answer'; // Your answer
export const KEY_HINT = 'app_hint'; // Hint:
export const KEY_CORRECT_ANSWER_TITLE = 'app_correct_answer_title'; // Good job!
export const KEY_CORRECT_ANSWER_TEXT = 'app_correct_answer_text'; // Your answer is correct!
export const KEY_TASK_FAILED = 'app_task_failed'; // Task failed
export const KEY_TASK_FAILED_TEXT = 'app_task_failed_text'; // Sorry you run out of tries. The correct answer was: #.
export const KEY_INCORRECT_ANSWER_TITLE = 'app_incorrect_answer_title'; // Try again
export const KEY_INCORRECT_ANSWER_TEXT = 'app_incorrect_answer_text'; // Sorry, this is not correct answer. You can try again.
export const KEY_NO_PUZZLES = 'app_no_puzzles';  // No puzzles discovered.
export const KEY_SWITCH_TO_SCANNER = 'app_switch_to_scanner'; // Switch to the scanner tab and scan a QR code to start the first puzzle!
export const KEY_MENU_TASKS = 'app_menu_tasks'; // Tasks
export const KEY_MENU_SCANNER = 'app_menu_scanner'; // QR Code
export const KEY_MENU_MAP = 'app_menu_map'; // Map
export const KEY_LOADING_DATA = 'app_loading_data'; // Connecting to database...
export const KEY_CANNOT_LOAD_GAME_DATA_FROM_API = 'app_cannot_load_game_data_from_api'; // Cannot load game data from API. Try refreshing page.
export const KEY_CANNOT_PERFORM_FIREBASE_AUTH = 'app_cannot_perform_firebase_auth'; // Cannot perform firebase authentication. Try refreshing page.
export const KEY_GAME_ALREADY_ENDED = 'app_game_already_ended'; // The game ended already. Please refresh page.
export const KEY_GENERIC_JOIN_ERROR = 'app_generic_join_error'; // Error: % Please refresh page.
export const KEY_ASK_SURE_LEAVE = 'app_ask_leave'; // Are you sure you want to leave the game?
export const KEY_ASK_SURE_LEAVE_TEXT = 'app_ask_leave_text'; // You can rejoin the game with the join code for up to 24 hours.
export const KEY_CANCEL = 'app_cancel'; // Cancel
export const KEY_LEAVE = 'app_leave'; // Leave
