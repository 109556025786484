import {useParams} from "react-router-dom";
import React, {useState} from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import {
    Answer,
    correctAnswersSelectorFactory,
    currentGameData,
    currentGameInstance,
    currentUserData,
    discoveredTaskIds,
    Hint,
    Instructions,
    Question,
    QUESTION_TYPE_OPTIONS,
    QUESTION_TYPE_TEXT,
    submitAnswer,
    TaskId,
    taskUseHint
} from "../redux";
import {
    KEY_CHECK_ANSWER,
    KEY_CORRECT_ANSWER_TEXT,
    KEY_CORRECT_ANSWER_TITLE,
    KEY_GIVE_HINT,
    KEY_HINT,
    KEY_INCORRECT_ANSWER_TEXT,
    KEY_INCORRECT_ANSWER_TITLE,
    KEY_QUESTION,
    KEY_TASK_FAILED,
    KEY_TASK_FAILED_TEXT,
    KEY_TASK_NOT_DISCOVERED,
    KEY_TASK_NOT_FOUND,
    KEY_YOUR_ANSWER,
    Translated,
    useTranslated
} from "../translations";
import {useDispatch, useSelector} from "react-redux";
import styles from './SingleTask.module.scss';
import {Box, Divider} from "@material-ui/core";
import {AlertTitle} from "@material-ui/lab";
import {green} from "@material-ui/core/colors";
import {useRealtimeDatabase} from "../firebase";
import ReactAudioPlayer from 'react-audio-player';
import {findCorrectAnswer, youtube_parser} from "../functions";
const ReactMarkdown = require('react-markdown')

export type CustomReactMarkdownTypes = {
    source: string;
};

function CustomReactMarkdown({source}: CustomReactMarkdownTypes) {
    return <ReactMarkdown source={source} renderers={{
        'paragraph': Typography,
    }}/>;
}

export type VideoEmbedProps = {
    src: string;
};

function VideoEmbed({src}: VideoEmbedProps) {
    if (src.includes("youtube") || src.includes("youtu.be")) {
        return <Box className={styles.youtubeContainer}>
            <iframe src={"https://www.youtube.com/embed/" + youtube_parser(src)} frameBorder="0"
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen/>
        </Box>;
    } else {
        return <video controls preload={'metadata'}>
            <source src={src}/>
        </video>;
    }
}

function TaskInstructions({title, paragraphs, image, video, audio}: Instructions) {
    return <>
        <Typography gutterBottom variant={"h4"}>{title}</Typography>
        {paragraphs.map((it, idx) => <CustomReactMarkdown key={idx} source={it}/>)}
        {image && <img src={image} alt={"related to the puzzle"}/>}
        {video && <VideoEmbed src={video}/>}
        {audio && <ReactAudioPlayer src={audio} controls/>}
    </>
}

export type OptionAnswerInputProps = {
    chooseAnswer: (id: string) => void;
    chosenAnswer: string;
    options: Answer[];
    highlightAnswers: boolean;
};

function OptionAnswerInput({chooseAnswer, chosenAnswer, options, highlightAnswers}: OptionAnswerInputProps) {
    return <RadioGroup value={chosenAnswer} onChange={(ev) => chooseAnswer(ev.target.value)}>
        {options.map(it => {
            const color = highlightAnswers && it.is_correct ? green['400'] : '';
            return <FormControlLabel key={it.id} value={it.id} control={<Radio style={{color}}/>}
                                     label={<span style={{color}}>{it.title}</span>}/>;
        })}
    </RadioGroup>
}

export type TextAnswerInputProps = {
    chooseAnswer: (id: string) => void;
    chosenAnswer: string;
    disabled: boolean;
};

function TextAnswerInput({chooseAnswer, chosenAnswer, disabled}: TextAnswerInputProps) {
    return <TextField label={useTranslated(KEY_YOUR_ANSWER)} value={chosenAnswer}
                      onChange={(ev) => chooseAnswer(ev.target.value)}
                      inputProps={{maxLength: 64}}
                      fullWidth disabled={disabled}/>
}

function TaskHint({text}: Hint) {
    return <Typography className={styles.hintText} gutterBottom
                       variant={"subtitle2"}><b>{useTranslated(KEY_HINT)}</b>: {text}</Typography>;
}

type TaskQuestionProps = {
    question: Question;
    taskId: TaskId;
};

function TaskQuestion({question, taskId}: TaskQuestionProps) {
    const dispatch = useDispatch();
    const db = useRealtimeDatabase();
    const gameInstance = useSelector(currentGameInstance);
    const userData = useSelector(currentUserData)[taskId];
    const hasTriesLeft = userData.tries < question.max_tries;
    const correctAnswers = useSelector(correctAnswersSelectorFactory(taskId));
    const hasCorrectAnswer = !!findCorrectAnswer(question, userData.last_answer);
    const canAnswer = hasTriesLeft && !hasCorrectAnswer;

    let [chosenAnswer, chooseAnswer] = useState(userData.last_answer ?? '');

    const onCheckAnswer = () => {
        if (chosenAnswer.trim() !== '' && gameInstance !== null) {
            dispatch(submitAnswer(db, gameInstance, taskId, chosenAnswer));
        }
    };

    const onGiveHint = () => {
        if (question.hint && gameInstance !== null) {
            dispatch(taskUseHint(db, gameInstance, taskId));
        }
    };

    let answerInput;
    switch (question.type) {
        case QUESTION_TYPE_OPTIONS:
            answerInput = <OptionAnswerInput highlightAnswers={!canAnswer}
                                             options={question.answers} {...{chooseAnswer, chosenAnswer}} />;
            break;
        case QUESTION_TYPE_TEXT:
            answerInput = <TextAnswerInput disabled={!canAnswer} {...{chooseAnswer, chosenAnswer}}/>;
            break;
        default:
            answerInput = <Alert severity={"error"}>Cannot display input for unsupported question
                type <code>{question.type}</code>!</Alert>
            break;
    }

    return <>
        <Typography gutterBottom variant={"h4"}><Translated translationKey={KEY_QUESTION}/></Typography>
        <CustomReactMarkdown source={question.text}/>
        {userData.used_hint_at && question.hint && <TaskHint {...question.hint}/>}

        {hasCorrectAnswer && <Alert className={styles.taskResolution} severity={"success"}>
            <AlertTitle><Translated translationKey={KEY_CORRECT_ANSWER_TITLE}/></AlertTitle>
            <Translated translationKey={KEY_CORRECT_ANSWER_TEXT}/>
        </Alert>}

        {!hasTriesLeft && !hasCorrectAnswer && <Alert className={styles.taskResolution} severity={"error"}>
            <AlertTitle><Translated translationKey={KEY_TASK_FAILED}/></AlertTitle>
            <Translated translationKey={KEY_TASK_FAILED_TEXT} replacement={correctAnswers[0].title}/>
        </Alert>}

        {hasTriesLeft && !hasCorrectAnswer && userData.last_answer != null &&
        <Alert className={styles.taskResolution} severity={"warning"}>
            <AlertTitle><Translated translationKey={KEY_INCORRECT_ANSWER_TITLE}/></AlertTitle>
            <Translated translationKey={KEY_INCORRECT_ANSWER_TEXT}/>
        </Alert>}

        <FormControl className={styles.answer} disabled={!hasTriesLeft || hasCorrectAnswer} component="fieldset">
            <Box className={styles.options}>{answerInput}</Box>

            <Button type="submit" variant="outlined"
                    disabled={chosenAnswer === '' || !canAnswer}
                    color={"secondary"} onClick={onCheckAnswer} fullWidth>
                <Translated translationKey={KEY_CHECK_ANSWER}/>
            </Button>

            {question.hint && <Button type="submit" variant="outlined"
                                      disabled={userData.used_hint_at !== null || hasCorrectAnswer}
                                      onClick={onGiveHint} fullWidth>
                <Translated translationKey={KEY_GIVE_HINT}/>
            </Button>}
        </FormControl>

        <Box className={styles.details}>
            <Divider style={{margin: '2em 0 1em 0'}}/>
            <Typography variant={"caption"}>Question
                pts: {question.answers.find(it => it.is_correct)?.base_points ?? 0}</Typography>
            <Typography variant={"caption"}>Hint penalty: {question.hint?.penalty_pts ?? 0}</Typography>
            <Typography variant={"caption"}>Max tries: {question.max_tries}</Typography>
            <Typography variant={"caption"}>Evaluation: {question.evaluation_algorithm}</Typography>
            <Typography variant={"caption"}>Your tries: {userData.tries}</Typography>
            <Typography variant={"caption"}>Used hint: {userData.used_hint_at === null ? 'no' : 'yes'}</Typography>
            <Typography variant={"caption"}>Last answer: {userData.last_answer}</Typography>
        </Box>
    </>;
}

export function SingleTask() {
    let {id} = useParams();
    let task = useSelector(currentGameData)?.tasks.find(it => it.id === id);
    let discoveredIds = useSelector(discoveredTaskIds);

    if (!task) {
        return <Alert severity={"error"}><Translated translationKey={KEY_TASK_NOT_FOUND}/></Alert>;
    }

    if (!discoveredIds.includes(task.id)) {
        return <Alert severity={"error"}><Translated translationKey={KEY_TASK_NOT_DISCOVERED}/></Alert>;
    }

    return <Container className={styles.singleTask}>
        <TaskInstructions {...task.instructions} />
        <TaskQuestion question={task.question} taskId={task.id}/>
    </Container>;
}
