import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/performance";
import "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDuVPIaG_sEP37Zmvgu4-w3qPViFAoIKwo",
    authDomain: "museum-app-c247e.firebaseapp.com",
    databaseURL: "https://museum-app-c247e.firebaseio.com",
    projectId: "museum-app-c247e",
    storageBucket: "museum-app-c247e.appspot.com",
    messagingSenderId: "268436811487",
    appId: "1:268436811487:web:5e58fb160e8aa70209436a",
    measurementId: "G-L22Y3SKSTP"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.performance();

const auth = firebase.auth();
const database = firebase.database();

export function useAuth(): firebase.auth.Auth {
    return auth;
}

export function useRealtimeDatabase(): firebase.database.Database {
    return database;
}
