import React, {useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter as Router, Link, Redirect, Route, Switch, useLocation} from "react-router-dom";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import ReorderIcon from '@material-ui/icons/Reorder';
import CropFreeIcon from '@material-ui/icons/CropFree';
import MapIcon from '@material-ui/icons/Map';
import {Box, CircularProgress, Container, createMuiTheme, ThemeProvider, Typography} from "@material-ui/core";
import {Qr} from "./pages/Qr";
import {SingleTask} from "./pages/SingleTask";
import {Tasks} from "./pages/TaskList";
import {Map} from "./pages/Map";
import {batch, Provider, useDispatch, useSelector} from 'react-redux';
import {applyMiddleware, createStore, Store} from 'redux';
import {currentGameInstance, GameInfo, isInGame, mergeUserData, museumAppLogic} from "./redux";
import {JoinGame} from "./pages/JoinGame";
import {Finish} from "./pages/Finish";
import {
    KEY_CANNOT_LOAD_GAME_DATA_FROM_API,
    KEY_CANNOT_PERFORM_FIREBASE_AUTH,
    KEY_GAME_ALREADY_ENDED,
    KEY_GENERIC_JOIN_ERROR,
    KEY_LOADING_DATA,
    KEY_MENU_MAP,
    KEY_MENU_SCANNER,
    KEY_MENU_TASKS,
    useTranslated
} from "./translations";
import "./firebase";
import {useAuth, useRealtimeDatabase} from "./firebase";
import {Api} from "./api";
import "firebase/database";
import thunk from 'redux-thunk';
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import {PersistGate} from "redux-persist/integration/react";


const store = createStore(persistReducer({
    key: '_data',
    storage
}, museumAppLogic), applyMiddleware(thunk));
const persistor = persistStore(store as unknown as Store);

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#8268e3'
        },
        secondary: {
            main: "#e77690"
        },
        type: 'dark',
    }
});

function NavBar() {
    const location = useLocation();
    const [bottomNavigation, setBottomNavigation] = useState(99);
    const inGame = useSelector(isInGame);
    const stringTasks = useTranslated(KEY_MENU_TASKS);
    const stringScanner = useTranslated(KEY_MENU_SCANNER);
    const stringMap = useTranslated(KEY_MENU_MAP);

    useEffect(() => {
        const loc = location.pathname;
        if (loc === '/tasks') {
            setBottomNavigation(0);
        } else if (loc === '/qr') {
            setBottomNavigation(1);
        } else if (loc === '/map') {
            setBottomNavigation(2);
        } else {
            setBottomNavigation(99);
        }
    }, [location]);

    return inGame ? <BottomNavigation
        className={"nav"}
        value={bottomNavigation}
        onChange={(_, v) => setBottomNavigation(v)}
        showLabels>
        <BottomNavigationAction component={Link} to={'/tasks'} label={stringTasks} icon={<ReorderIcon/>}/>
        <BottomNavigationAction component={Link} to={'/qr'} label={stringScanner} icon={<CropFreeIcon/>}/>
        <BottomNavigationAction component={Link} to={'/map'} label={stringMap} icon={<MapIcon/>}/>
    </BottomNavigation> : (null);
}

function InGameRoute(props: any) {
    const inGame = useSelector(isInGame);

    if (inGame) {
        return <Route {...props}/>;
    }
    return <Redirect to={'/'}/>;
}

export type SyncProps = {
    setLoading: (arg0: boolean) => void;
    setLoadingMessage: (arg0: string) => void;
};

function Sync({setLoading, setLoadingMessage}: SyncProps) {
    const auth = useAuth();
    const db = useRealtimeDatabase();
    const dispatch = useDispatch();
    const gameInstance = useSelector(currentGameInstance);
    const loadingMessage = useTranslated(KEY_LOADING_DATA);
    const apiErrorMessage = useTranslated(KEY_CANNOT_LOAD_GAME_DATA_FROM_API);
    const fireBaseErrorMessage = useTranslated(KEY_CANNOT_PERFORM_FIREBASE_AUTH);
    const gameEndedMessage = useTranslated(KEY_GAME_ALREADY_ENDED);
    const genericJoinError = useTranslated(KEY_GENERIC_JOIN_ERROR);

    useEffect(() => {
        let reference: firebase.database.Reference | null = null;
        let shouldCancel = false;
        (async () => {
            if (gameInstance === null) {
                return console.log("Currently in no game.");
            }

            setLoadingMessage(loadingMessage);
            setLoading(true);
            let configuration: GameInfo;
            try {
                configuration = await Api.getGameInfo(gameInstance.code);
            } catch (e) {
                setLoadingMessage(apiErrorMessage);
                return console.error("Cannot get game info from api.");
            }

            try {
                await auth.signInWithCustomToken(configuration.jwt);
            } catch (e) {
                setLoadingMessage(fireBaseErrorMessage);
                return console.error("cannot sign in user with firebase auth.")
            }

            reference = db.ref(`games/${configuration.code}/user_data`);
            reference.on('value', (snapshot) => {
                if (snapshot.exists()) {
                    batch(() => {
                        dispatch(mergeUserData(snapshot.val()));
                        setLoading(false);
                    });
                } else {
                    setLoading(false);
                }
            }, (error: Error) => {
                console.error(error);
                localStorage.clear();
                if (error.message.includes('permission_denied')) {
                    setLoadingMessage(gameEndedMessage);
                } else {
                    setLoadingMessage(genericJoinError.replace('%', error.message));
                }
            });

            if (shouldCancel) {
                console.warn("effect was canceled before it had chance to finish");
                reference?.off();
                setLoading(false);
            }
        })();
        return () => {
            shouldCancel = true;
            setLoading(false);

            if (reference != null) {
                reference.off();
            }
        };
    }, [dispatch, setLoadingMessage, loadingMessage, apiErrorMessage, genericJoinError,
        fireBaseErrorMessage, gameEndedMessage, setLoading, auth, db, gameInstance]);

    return (null);
}

function App() {
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');

    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <Sync setLoading={setLoading} setLoadingMessage={setLoadingMessage}/>
                <Router>
                    <ThemeProvider theme={theme}>
                        {loading ? <Container className={"loadingParent"}>
                                <Box>
                                    <CircularProgress/>
                                    <Typography>{loadingMessage}</Typography>
                                </Box>
                            </Container> :
                            <>
                                <main>
                                    <Switch>
                                        <InGameRoute path="/tasks" component={Tasks}/>
                                        <InGameRoute path="/task/:id" component={SingleTask}/>
                                        <InGameRoute path="/qr" component={Qr}/>
                                        <InGameRoute path="/map" component={Map}/>
                                        <Route path="/finish" component={Finish}/>
                                        <Route exact path="/" component={JoinGame}/>
                                    </Switch>
                                </main>
                                <NavBar/>
                            </>}
                    </ThemeProvider>
                </Router>
            </PersistGate>
        </Provider>
    );
}

export default App;
